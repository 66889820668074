@import "../../assets/scss/magic";
.gallery-head {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: rem(20) 0 0;
  margin: 0 0 rem(60);
  overflow: hidden;
  @include mobile {
    margin: 0 0 rem(40);
  }
}
.gallery-title {
  color: #414243;
  font: 700 rem(70) / 1 $fh;
  position: relative;
  text-transform: capitalize;
  @include mobile {
    font-size: rem(34);
  }
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    top: 60%;
    border-top: 1px solid #414243;
    margin: 0 rem(40);
    width: rem(120);
    @include mobile {
      width: rem(30);
      margin: 0 rem(20);
    }
  }
  &:before {
    left: 100%;
  }
  &:after {
    right: 100%;
  }
}
.gallery-tabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: rem(24) 0 0;
  button {
    margin: 0 rem(30);
    font: 700 rem(30) / 1 $fh;
    color: #414243;
    position: relative;
    transition: 400ms color;
    cursor: pointer;
    @include mobile {
      font-size: rem(24);
    }
    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      top: 60%;
      background: #535769;
      margin: 0 rem(12);
      width: rem(5);
      height: rem(5);
      border-radius: 100%;
      transition: 400ms opacity, 400ms left, 400ms right, 400ms background;
      opacity: 0;
    }
    &:before {
      left: 80%;
    }
    &:after {
      right: 80%;
    }
    &.active {
      pointer-events: none;
      color: $hero;
      &:before, &:after {
        background: $hero;
      }
    }
    @include hover-active {
      &:before, &:after {
        opacity: 1;
      }
      &:before {
        left: 100%;
      }
      &:after {
        right: 100%;
      }
    }
  }
}
