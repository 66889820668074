@use "sass:math";
@mixin placeholder {
  &::-webkit-input-placeholder {@content; opacity: 1;}
  &:-moz-placeholder           {@content; opacity: 1;}
  &::-moz-placeholder          {@content; opacity: 1;}
  &:-ms-input-placeholder      {@content; opacity: 1;}
}
@mixin rtl {
  html[dir='rtl'] &{
    @content;
  }
}
@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}
@mixin hover-active {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
  &.active {
    @content;
  }
}
@function rem($size) {
  @if $size == 1 {
    @return #{$size}px;
  }
  $remSize: math.div($size, $baseFontSize);
  @return #{$remSize}rem;
}
@function -rem($size) {
  @if $size == 1 {
    @return -#{$size}px;
  }
  $remSize: math.div($size, $baseFontSize);
  @return -#{$remSize}rem;
}
@mixin desktop {
  @media screen and (min-width: 569px){
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 568px){
    @content;
  }
}
