@import "../../assets/scss/magic";
@import "conversational-form/mixins/cf-mixins";

$cf-base-font-size: 16px;
$cf-mobile-font-size: 16px;

$cf-color-primary: $hero;
$cf-color-secondary: #f3f3f5;

$cf-font-family: $f, 'Helvetica Neue','Neue Helvetica W01',Helvetica,Arial,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol',sans-serif !default;

@import "conversational-form/conversational-form";
.conversational-form {
  line-height: $cf-chat-response-font-size * 1.5;
}
cf-chat-response {
  &.user {
    thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: darken($cf-color-secondary, 30);
    }
  }
  text {
    align-items: flex-start;
  }
}
cf-input {
  .inputWrapper {
    input, textarea {
      padding-inline-start: rem(26);
      &:focus, &:active {
        box-shadow: 0 0 1px black;
      }
    }
    > * {
      border-radius: rem(26) !important;
      @include placeholder {
        transition: all 400ms;
      }
    }
  }
}
.cf-button {
  &.has-image {
    max-width: 180px;
    img {
      width: 180px;
      height: 130px;
      object-fit: cover;
    }
    @media (max-width: $cf-breakpoint-medium) {
      max-width: 142px;
      img {
        width: 142px;
        height: 100px;
      }
    }
  }
}
