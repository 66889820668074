@import "../../assets/scss/magic";
.apply-buttons-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: rem(20);
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(46);
    padding: 0 rem(30);
    background: $hero;
    color: white;
    transition: 400ms transform;
    border-radius: rem(100);
    min-width: rem(285);
    &:last-child {
      background: #0e0d0d;
    }
    @include hover {
      transform: scale(1.05);
    }
  }
}
