@import "magic";
* {
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0;
    border-radius: 0;
    background: none;
    vertical-align: top;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-tap-highlight-color: transparent;
    &, &:before, &:after {
        box-sizing: border-box;
    }
}
strong, b {
    font-family: $fh;
}
a {
    text-decoration: none;
    transition: 300ms color, 300ms background;
}
li {
    display: block;
}
input, select, button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input:not([type="button"]):not([type="submit"]), select {
    @include mobile {
        font-size: 16px !important;
        @include placeholder {
            font-size: 16px !important;
        }
    }
}
.hide {
    display: none;
}
.pointer {
    cursor: pointer;
}
.pointer-events-none {
    pointer-events: none;
}
.guide {
    max-width: rem(1134);
    width: 100%;
    padding: 0 rem(20);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include mobile {
        padding: 0 20px;
    }
}
html {
    -webkit-text-size-adjust: 100%;
    user-select: none;
    font: 300 #{$baseFontSize}px / 1.7 $f;
    color: #414243;
    --c100vh: 100vh;
    @media screen and (max-width: 1160px) {
        font-size: 20px;
    }
    @media screen and (max-width: 1046px) {
        font-size: 18px;
    }
    @media screen and (max-width: 960px) {
        font-size: 16px;
    }
    @media screen and (max-width: 840px) {
        font-size: 14px;
    }
    @media screen and (max-width: 766px) {
        font-size: 12px;
    }
    @include mobile {
        font-size: 18px;
    }
}
body {
    background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    --header-height: #{rem(76)};
    --header-text-color: #414243;
    &.not-regular-page:not(.show-menu):not(.sticky) {
        --header-text-color: #ffff;
    }
    @include mobile {
        --header-height: 62px;
        background: white;
    }
}
@include desktop {
    html, body {
        overflow: hidden;
        &, .all-holder, .layer-3d-holder {
            height: 100%;
            width: 100%;
        }
    }
    .layer-3d-holder {
        position: fixed;
        top: 0;
        left: 0;
        perspective: 100vw;
        transform: translate3d(0, 0, 0);
    }
    .all-holder {
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        overflow-y: auto;
        overflow-x: hidden;
        display: grid;
        grid-template-rows: 12fr auto;
        grid-template-areas: 'all' 'footer';
        transform: translate3d(0, 0, 0);
        transform-origin: 50% 100%;
        box-shadow: 0 rem(140) rem(300) -#{rem(20)} rgba(#FF2622, 0);
        transition: transform 0.4s ease-in-out, box-shadow 400ms ease-in-out 200ms;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
        .show-menu & {
            transform: translate3d(0, 2vw, -28vw) rotateX(50deg);
            transform-origin: 50% 100%;
            box-shadow: 0 rem(140) rem(300) -#{rem(20)} rgba(#121623, 0.4);
        }
        main {
            grid-area: all;
            display: flex;
            flex-direction: column;
        }
        footer {
            grid-area: footer;
        }
    }
}
.show-mobile {
    @include desktop {
        display: none !important;
    }
}
.show-desktop {
    @include mobile {
        display: none !important;
    }
}
@include mobile {
    .all-holder {
        transform: scale(1);
        transition: 600ms transform;
        .show-menu & {
            transform: scale(0.8);
        }
    }
}
