@import "../../assets/scss/magic";
.our-adviser-title {
    h2 {
        border-color: #aead93;
    }
}
.our-adviser {
    width: 100%;
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: rem(24) 0 rem(56);
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        li {
            width: 20%;
            margin: rem(24) 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            @include mobile {
                width: 50%;
            }
            p {
                font-family: $fh;
                color: #aead93;
            }
            i {
                display: block;
                width: rem(10);
                height: 1px;
                background: rgba(#9a202f, 0.2);
                margin: rem(12) 0 0;
            }
            small {
                margin: rem(8) 0 0;
                color: rgba(#121623, 0.5);
                display: block;
                font: 700 rem(10) / rem(12) $f;
                letter-spacing: rem(1);
                text-transform: uppercase;
            }
        }
    }
}
