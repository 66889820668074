@import "../../assets/scss/magic";
footer {
    width: 100%;
    background: white;
    padding: rem(80) 0;
    @include mobile {
        padding: rem(60) 0;
    }
}
.footer-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 rem(80);
    @include mobile {
        text-align: center;
        margin: 0 0 rem(40);
    }
    h4 {
        font: 700 rem(26) / rem(28) $fh;
        margin: 0 0 rem(50);
        @include mobile {
            margin: 0 0 rem(40);
        }
    }
}
.footer-nav {
    width: 100%;
    @include desktop {
        width: 70%;
    }
    ul {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: rem(35);
        grid-column-gap: rem(20);
        li {
            display: flex;
            overflow: hidden;
            @include mobile {
                justify-content: flex-start;
            }
        }
    }
    a {
        font: 300 rem(20) / rem(22) $f;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #3f4553;
        border-bottom: rem(1) dashed $hero;
        @include mobile {
            font: 300 rem(18) / rem(20) $f;
        }
        @include hover-active {
            color: $hero;
        }
    }
}
.footer-side {
    width: 100%;
    @include mobile {
        margin: rem(80) 0 0;
    }
    @include desktop {
        width: 30%;
        text-align: end;
    }
    a {
        color: $hero;
        font: 700 rem(20) / rem(22) $fh;
        margin: 0 0 rem(40);
        display: block;
        @include mobile {
            margin: 0 0 rem(20);
        }
    }
    .license {
        font: rem(12) / rem(24) $f;
    }
}
.footer-powered {
    width: 100%;
    text-align: center;
    font: 700 rem(10) / rem(14) $f;
    text-transform: uppercase;
    color: rgba(#3f4553, 0.8);
    letter-spacing: rem(5);
    a {
        font: inherit;
        color: $hero;
        @include hover-active {
            color: darken($hero, 10);
        }
    }
}
