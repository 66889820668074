@import "../../assets/scss/magic";
.loader-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(rem(4));
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 300ms opacity, 300ms visibility;
  z-index: 1000;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
.loader-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-element {
  width: rem(49);
  height: rem(49);
  margin: auto;
  position: relative;
  &:before {
    content: '';
    width: rem(49);
    height: rem(5);
    background: black;
    opacity: 0.1;
    position: absolute;
    top: rem(58);
    left: 0;
    border-radius: 50%;
    animation: shadow 0.425s linear infinite;
  }
  &:after {
    content: '';
    width: rem(49);
    height: rem(49);
    background: #ff5b09;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: rem(3);
    animation: loader-cube 0.425s linear infinite;
  }
}
@keyframes loader-cube {
  17% {
    border-bottom-right-radius: rem(3);
  }
  25% {
    transform: translateY(#{rem(9)}) rotate(22.5deg);
  }
  50% {
    transform: translateY(#{rem(18)}) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: rem(39);
  }
  75% {
    transform: translateY(#{rem(9)}) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes shadow {
  0%, 100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}
