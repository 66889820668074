@import "../../assets/scss/magic";
.intro-section {
  background: no-repeat 50% 8% / cover;
  position: relative;
  overflow: hidden;
  transform: translate3d(0,0,0);
  &.black-background {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background: linear-gradient(to bottom, rgba(black, 0.3) 0%, transparent 100%);
      pointer-events: none;
      transform: translate3d(0,0,0);
    }
  }
  video {
    width: 100%;
    height: 100%;
    display: block;
    pointer-events: none;
    object-fit: cover;
    object-position: center center;
  }
  &.full-screen:not([style]) {
    background: $hero;
  }
}
.curve-button {
  position: absolute;
  bottom: -1px;
  transform: translateX(-50%);
  left: 50%;
  font-size: rem(50);
  color: white;
  z-index: 20;
  @include mobile {
    font-size: 60px;
  }
}
.splash-guide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  z-index: 10;
  justify-content: center;
  mix-blend-mode: overlay;
  align-items: flex-start;
  transform: translate3d(0,0,0);
  & + & {
    mix-blend-mode: normal;
  }
  .opacity {
    opacity: 0;
    pointer-events: none;
  }
  .guide {
    height: 100%;
    justify-content: center;
    align-items: center;
    @include mobile {
      padding: 0 8px;
    }
  }
  h1 {
    color: #ffffff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
    &.about {
      align-items: flex-start;
      padding: var(--header-height) 0 0;
    }
  }
  img {
    display: block;
    width: 100%;
    margin: 0 0 rem(20);
    @include desktop {
      max-height: 60vh;
      object-fit: contain;
      object-position: center;
      max-width: 56%;
    }
    &.opacity {
      mix-blend-mode: normal;
      opacity: 0.5;
    }
  }
}
.full-screen {
  height: var(--c100vh);
  transition: 400ms height;
}
