@import "../../assets/scss/magic";
body {
  @include desktop {
    &:before {
      $hero: #121623;
      content: "";
      display: block;
      position: fixed;
      right: rem(460);
      top: 0;
      width: rem(160);
      height: 100%;
      z-index: 2000;
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba($hero,0.03) 100%);
      pointer-events: none;
      opacity: 0;
      @include rtl {
        left: rem(460);
        right: auto;
        background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba($hero,0.03) 100%);
      }
    }
  }
}
.apply-form-wrapper {
  width: rem(460);
  background: #fff;
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 2000;
  height: 100%;
  padding: rem(10) 0 0 0;
  text-align: center;
  @include rtl {
    left: auto;
    right: 100%;
  }
  @include mobile {
    width: 100%;
  }
  h2 {
    margin: rem(12) 0;
    display: inline-block;
    vertical-align: top;
    float: none;
    border: 0;
    @include rtl {
      float: none;
    }
  }
  .apply-form-holder {
    height: calc(100% - #{rem(81)});
    display: flex;
    position: relative;
    text-align: start;
  }
  .toggle-apply-form {
    position: absolute;
    top: rem(26);
    right: rem(26);
    font: rem(9) / 1 'icomoon', serif;
    background: #272e40;
    padding: rem(10);
    color: white;
    border-radius: 100%;
    transition: 300ms background, 300ms transform;
    cursor: pointer;
    @include rtl {
      right: auto;
      left: rem(26);
    }
    &:before {
      content: "\e98c";
    }
    &:hover {
      background: $hero;
      transform: rotate(180deg);
    }
  }
}
.apply-form-wrapper, .layer-3d-holder {
  transition: 300ms transform;
  .apply-page & {
    height: 1px;
    overflow: hidden;
    transform: translate3d(0,0,0);
  }
}
.show-apply-form {
  &:before {
    opacity: 1;
    transition: 300ms opacity;
    transition-delay: 200ms;
  }
  .layer-3d-holder {
    transform: translateX(-#{rem(460)});
    @include rtl {
      transform: translateX(#{rem(460)});
    }
    @include mobile {
      transform: translateX(-100%);
      @include rtl {
        transform: translateX(100%);
      }
    }
  }
  .apply-form-wrapper {
    transform: translateX(-100%);
    @include rtl {
      transform: translateX(100%);
    }
  }
}
