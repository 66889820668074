@import "../../assets/scss/magic";
.videos-block {
  @include desktop {
    height: 100vh;
    @media (orientation: portrait) {
      height: 54vh;
    }
  }
  display: grid;
  grid-template-columns: 66.67% 33.33%;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "first second" "first third";
  @include mobile {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: "first first" "second third";
  }
  .videos-block-item {
    position: relative;
    overflow: hidden;
    @include mobile {
      padding: 100% 0 0;
    }
    &:nth-child(1) {
      grid-area: first;
    }
    &:nth-child(2) {
      grid-area: second;
    }
    &:nth-child(3) {
      grid-area: third;
    }
  }
  button {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 20%;
    }
  }
  .icon-yube {
    &:before {
      display: block;
      position: absolute;
      font-size: rem(60);
      top: 50%;
      left: 50%;
      color: white;
      transition: 500ms transform;
      transform: translate(-50%, -50%) scale(1);
      z-index: 20;
      @include mobile {
        font-size: 30px;
      }
    }
    &:hover {
      &:before {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
}
