@import "../../assets/scss/magic";
.team-member {
    width: 100%;
    @include desktop {
        max-width: 90%;
    }
    display: grid;
    grid-template-columns: 36% 1fr;
    grid-column-gap: rem(40);
    grid-row-gap: 14px;
    margin: 0 0 rem(80);
    align-items: flex-start;
    @include mobile {
        grid-template-columns: 100%;
        grid-template-rows: auto 1fr;
        margin: 0 0 30px;
    }
    > li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &:first-child {
        margin-top: rem(90);
        @include mobile {
            margin-top: 30px;
        }
    }
    figure {
        width: 100%;
        padding: 100% 0 0;
        background: no-repeat 50% 50% / cover;
        border-radius: rem(5);
    }
    h2 {
        border: 0;
        margin: 0 0 rem(24);
        line-height: 1;
        font-size: rem(50);
        @include mobile {
            align-self: center;
            line-height: 1.2;
            text-align: center;
        }
        span {
            display: inline-block;
        }
    }
    h3 {
        margin: 0 0 rem(24);
        text-transform: uppercase;
        letter-spacing: rem(1);
        font-size: rem(12);
        padding: 0 rem(10);
        line-height: rem(30);
        font-weight: 700;
        border: 1px solid darken($hero, 5);
        border-radius: rem(5);
        color: darken($hero, 5);
        font-family: $f;
        @include mobile {
            align-self: center;
        }
    }
    p {
        a {
            color: $hero;
            &:hover {
                color: darken($hero, 10);
            }
        }
    }
}
