@import "../../assets/scss/magic";
header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    padding: rem(14) 0;
    z-index: 10000;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate3d(0, -100%, 0);
        background: white;
        body:not(.show-menu).sticky & {
            transition: 400ms transform;
            transform: translate3d(0, 0, 0);
        }
    }
}
.header-content {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    width: 100%;
    transform: translate3d(0, 0, 0);
    > li {
        display: flex;
        align-items: center;
        &:not(:nth-child(2)) {
            flex: 1;
        }
        &:nth-child(3) {
            justify-content: flex-end;
        }
    }
}
.icon-logo {
    display: block;
    font-size: rem(48);
    color: var(--header-text-color);
    transition: 400ms color, 400ms transform;
    transform: translate3d(0, rem(14), 0);
    @include hover {
        color: $hero;
    }
    body:not(.show-menu).sticky & {
        transform: translate3d(0, 0, 0) scale(0.7);
    }
}
.menu-button {
    width: rem(48);
    height: rem(48);
    cursor: pointer;
    position: relative;
    margin: 0 0 0 rem(12);
    i {
        display: block;
        position: absolute;
        left: rem(12);
        width: rem(24);
        height: 1px;
        background: var(--header-text-color);
        z-index: 10;
        transition: 0.3s transform ease-in-out, 0.3s top, 0.3s left, 300ms background;
        &:nth-child(1) {
            top: rem(16);
            .show-menu & {
                transform: translateX(-50%) rotate(45deg);
                top: 50%;
                left: 50%;
            }
        }
        &:nth-child(2) {
            top: rem(24);
            .show-menu & {
                transform: scaleX(0);
            }
        }
        &:nth-child(3) {
            top: rem(32);
            .show-menu & {
                transform: translateX(-50%) rotate(-45deg);
                top: 50%;
                left: 50%;
            }
        }
        @include mobile {
            .show-menu & {
                background: #ff523f;
            }
        }
    }
}
.apply-as-link {
    padding: 0 rem(20);
    height: rem(40);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: rem(2);
    font: 700 rem(12) / normal Roboto;
    color: $hero;
    transition: 400ms transform;
    &:hover {
        transform: translateY(-#{rem(5)});
    }
    body.not-regular-page:not(.show-menu):not(.sticky) & {
        color: var(--header-text-color);
    }
}
.navigation-holder {
    @include desktop {
        padding: var(--header-height) 0 0;
        height: 44vh;
        display: flex;
        align-items: center;
        position: relative;
        .show-menu & {
            z-index: 1010;
        }
        nav {
            width: 100%;
            > ul {
                width: 100%;
                display: flex;
                justify-content: center;
                > li {
                    position: relative;
                    transform: translateY(-#{rem(200)});
                    opacity: 0;
                    transition: transform 0.4s, opacity 0.4s;
                    + li {
                        margin: 0 0 0 rem(50);
                        @include rtl {
                            margin: 0 rem(50) 0 0;
                        }
                    }
                    &.show-mobile:first-child + li {
                        margin: 0;
                    }
                    .show-menu & {
                        transform: translateY(0);
                        opacity: 1;
                        $s: 0.1;
                        $a: 0.04;
                        @for $i from 0 through 10 {
                            &:nth-child(#{$i}) {
                                transition-delay: #{$s + $a}s;
                                $s: $s + $a;
                            }
                        }
                    }
                }
            }
            .nav-item {
                display: flex;
                align-items: center;
                font: 300 rem(22) / normal $f;
                text-transform: uppercase;
                letter-spacing: rem(2);
                color: #414243;
                position: relative;
                cursor: pointer;
                &:before {
                    content: attr(title);
                    position: absolute;
                    top: 0;
                    left: 0;
                    max-width: 0;
                    overflow: hidden;
                    color: #000;
                    height: 100%;
                    width: 100%;
                    transition: max-width .25s;
                    @include rtl {
                        left: auto;
                        right: 0;
                    }
                }
                .icon-down {
                    font-size: rem(16);
                    color: #4a4f5a;
                    opacity: 0.4;
                    margin: 0 0 0 rem(6);
                    transition: 500ms transform;
                }
                &.active {
                    pointer-events: none;
                }
                &:hover, &.active {
                    &:before {
                        max-width: 100%;
                    }
                    .icon-down {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    @include mobile {
        position: fixed;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1010;
        transition: 400ms transform;
        transform: translate3d(0, 0, 0);
        background-image: linear-gradient(135deg, #ffffff 0%, #fff3f8 100%);
        box-shadow: 0 100px 0 #fff3f8;
        display: flex;
        overflow-y: auto;
        padding: var(--header-height) 0 30px;
        -webkit-overflow-scrolling: touch;
        .show-menu & {
            transform: translate3d(0, -100%, 0);
            transition-delay: 100ms;
        }
        .guide {
            margin: auto;
            padding: 0;
        }
        nav {
            width: 100%;
            ul {
                width: 100%;
                display: flex;
                flex-direction: column;
                li {
                    width: 100%;
                    position: relative;
                }
            }
            .nav-item {
                display: block;
                font: 300 16px / 56px $f;
                letter-spacing: 2px;
                color: #925570;
                padding: 0 50px;
                position: relative;
                &.active {
                    color: #ff523f;
                    font-weight: 400;
                }
            }
        }
    }
}
.dropdown-menu {
    @include mobile {
        ~ .nav-item {
            display: none !important;
        }
    }
    @include desktop {
        position: absolute;
        top: 100%;
        left: -#{rem(28)};
        @include rtl {
            left: auto;
            right: -#{rem(28)};
        }
        white-space: nowrap;
        overflow: hidden;
        box-shadow: 0 rem(20) rem(30) -#{rem(10)} rgba(0, 0, 0, 0);
        padding: rem(20) 0 0;
        max-height: 0;
        transition: 350ms max-height, box-shadow 400ms;
        transition-delay: 250ms;
        transform: translate3d(0, 0, 0);
        li:hover > & {
            max-height: rem(290);
            box-shadow: 0 rem(20) rem(30) -#{rem(10)} rgba(0, 0, 0, 0.3);
        }
        ul {
            background: #292d3c;
            padding: rem(12) 0;
            flex-direction: column;
            position: relative;
            &:before {
                content: "";
                display: block;
                width: 200%;
                height: rem(60);
                position: absolute;
                top: 115%;
                left: -50%;
                border-radius: 100%;
                box-shadow: 0 0 rem(170) -#{rem(10)} #00ffef;
            }
            .nav-item {
                color: #b1d7da;
                text-transform: capitalize;
                font-size: rem(15);
                letter-spacing: 0;
                margin: rem(16) rem(28);
                font-weight: 300;
                &:before {
                    color: white;
                }
            }
        }
    }
}
.mobile-header-content {
    width: 100%;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .button.medium {
        width: 100%;
        height: 50px;
        font-size: 14px;
        letter-spacing: 2px;
        margin: 20px 0;
    }
}
.mobile-header-footer {
    display: grid;
    grid-template-columns: 1fr;
}
.lang-switcher-mobile {
    position: relative;
    display: flex;
    select {
        padding: 0 0 0 32px;
        width: 100%;
        font-weight: 300;
        color: #403e66;
    }
    .icon-down {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 14px;
        color: #403e66;
        opacity: 0.5;
    }
}
