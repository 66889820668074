@import "../../assets/scss/magic";
.out-partners-section {
    width: 100%;
    padding: rem(60) 0 0;
}
.our-partners-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: rem(38) 0 rem(68);
    width: 100%;
    @include desktop {
        gap: rem(20);
    }
    @include mobile {
        border-radius: rem(8);
        overflow: hidden;
    }
    a {
        display: block;
        position: relative;
        background: no-repeat 50% 50% / 60%;
        transition: 700ms transform, 700ms filter, 700ms border-radius, 700ms box-shadow, 400ms background;
        box-shadow: 0 rem(26) rem(52) -#{rem(10)} rgba(197, 125, 35, 0);
        transform: scale(1);
        width: calc(100% / 7);
        border-radius: rem(10);
        img, picture {
            width: 100%;
            border-radius: inherit;
        }
        @include mobile {
            width: calc(100% / 2);
        }
        @include hover {
            background: white;
            transform: scale(1.1);
            filter: saturate(2) grayscale(0%);
            box-shadow: 0 rem(26) rem(52) -#{rem(10)} rgba(197, 125, 35, 0.39);
            z-index: 10;
        }
    }
}
