@import "../../assets/scss/magic";
.press-head {
    width: 100%;
    height: rem(360);
    font: 700 rem(80) / 1 $fh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .press-title {
        width: 100%;
        color: $hero;
        @include mobile {
            font-size: rem(60) !important;
        }
    }
    @include mobile {
        height: rem(250);
    }
}
.press-data-holder {
    width: 100%;
    padding: rem(40) 0 rem(100);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: rem(40);
    grid-row-gap: rem(60);
    text-align: center;
    @include mobile {
        grid-template-columns: 100%;
    }
    li {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .press-name {
            display: block;
            max-width: 100%;
            font: 700 rem(70) / 1 $fh;
            color: #414243;
            margin: 0 0 rem(24);
            @include mobile {
                font-size: rem(50);
            }
            @include hover {
                color: black;
            }
        }
        .press-title {
            display: block;
            max-width: 100%;
            font: 300 rem(16) / 2 $f;
            text-transform: uppercase;
            letter-spacing: rem(1);
            color: #414243;
            padding: 0 rem(50);
            @include hover {
                color: black;
            }
            @include mobile {
                font-size: rem(14);
            }
        }
    }
    .line {
        width: rem(120);
        height: rem(1);
        background: linear-gradient(to right, #f69605, #ff2323);
        margin: 0 0 rem(20);
    }
}
