@import '../../assets/scss/magic';
.patrons-holder {
    width: 100%;
    display: flex;
    margin: rem(30) 0 rem(120);
    filter: saturate(1.5);
    justify-content: center;
    @include mobile {
        flex-wrap: wrap;
        margin: 0 0 rem(80);
    }
    > ul {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 1fr auto;
        text-align: center;
        font: 500 rem(12) / 1 $f;
        grid-row-gap: rem(20);
        @include mobile {
            width: auto;
            margin: rem(20);
        }
        > li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > img {
                width: rem(50);
                max-height: rem(70);
                object-fit: contain;
                @include mobile {
                    width: rem(30);
                    max-height: rem(45);
                }
            }
        }
    }
}
