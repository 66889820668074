@import "../../assets/scss/magic";
#mc_embed_signup {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: rem(20) 0;
    width: 100%;
    @include mobile {
        margin: 40px 0 30px;
    }
    form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: rem(500);
        @include desktop {
            background: white;
            padding: rem(28);
            border-radius: rem(8);
        }
        @include mobile {
            padding: 0 20px;
        }
    }
    .subscribe-title {
        display: block;
        color: #6d0505;
        font: 700 rem(12) / rem(14) $f;
        text-transform: uppercase;
        letter-spacing: rem(1);
        margin: 0 0 rem(14);
    }
}
#mc_embed_signup_scroll {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: rem(12);
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .mc-field-group {
        input {
            border: 1px dashed #cc9a0d;
            width: 100%;
            display: block;
            border-radius: rem(4);
            height: rem(50);
            padding: 0 rem(24);
            font: 300 rem(14) $f;
            color: #8a580b;
            @include mobile {
                height: 50px;
            }
            &:-webkit-autofill {
                -webkit-text-fill-color: #8a580b;
                box-shadow: inset 0 0 0 rem(100) white;
                background-clip: content-box;
            }
            @include placeholder {
                color: #cc9a0d;
                font-weight: 300;
                font-family: $f;
                font-size: rem(14);
            }
            &:focus {
                border-style: solid;
            }
        }
    }
}
