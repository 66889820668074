@import "magic";
@import "reset";
@import "fonts";
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(48);
    padding: 0 rem(36);
    background: $hero;
    color: white;
    font: 400 rem(13) / normal $f;
    text-transform: uppercase;
    border-radius: rem(8);
    cursor: pointer;
    letter-spacing: rem(0.2);
    position: relative;
    overflow: hidden;
    transition: 400ms transform;
    &.medium {
        height: rem(40);
        padding: 0 rem(28);
        font-size: rem(12);
    }
    &.temp-big {
        height: rem(44);
        padding: 0 rem(36);
        font-size: rem(13);
        letter-spacing: rem(0.5);
    }
    @include mobile {
        align-self: center;
        height: 48px;
        padding: 0 36px;
        font-size: 12px;
    }
    &.shadow {
        box-shadow: 0 rem(8) rem(40) -#{rem(10)} rgba(black, 0.3);
    }
    &.secondary {
        color: #fff;
    }
    &.blurry {
        background: white;
        color: #414243;
    }
    &:hover {
        transform: translateY(-#{rem(5)});
    }
    &.has-mobile-icon {
        @include mobile {
            padding: 0;
            width: 48px;
            font-size: 20px;
        }
    }
}
.sqs-block-content {
    margin: rem(72) 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.9em;
    @include mobile {
        margin: 40px 0;
    }
    @include desktop {
        padding: 0 30% 0 10%;
        @include rtl {
            padding: 0 10% 0 30%;
        }
    }
    p {
        a {
            color: $hero;
            font-weight: 400;
            @include hover {
                color: darken($hero, 10);
            }
        }
        + p {
            margin: rem(18) 0;
        }
    }
    b {
        font-family: $f;
        font-weight: 700;
    }
    strong {
        color: #414243;
    }
    img {
        max-width: 100%;
    }
    a {
        &:not([class]) {
            color: $hero;
            &:hover {
                color: darken($hero, 10);
            }
        }
    }
    ul, ol {
        width: 100%;
        margin: rem(20) 0 0;
        font-size: 0.9em;
        li {
            width: 100%;
            margin-bottom: rem(20);
            padding: 0 0 0 rem(60);
            position: relative;
            @include rtl {
                padding: 0 rem(60) 0 0;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: rem(8);
                left: rem(24);
                width: rem(16);
                height: rem(16);
                border: 1px solid $hero;
                border-radius: 38%;
                color: $hero;
                font: rem(28) / 1 $fh;
                @include rtl {
                    left: auto;
                    right: rem(24);
                }
            }
        }
    }
    ol {
        counter-reset: section;
        > li {
            counter-increment: section;
            &:before {
                content: counter(section);
                border: 0;
                top: -#{rem(2)};
            }
            > ul > li:before {
                background: $hero;
            }
        }
    }
    @include desktop {
        .static-second & {
            padding: 0 16%;
        }
        .static-city & {
            padding: 0 16%;
        }
    }
}
h1 {
    font: 700 rem(52) / 1 $fh;
    text-transform: capitalize;
    &:not(.intro-title) {
        line-height: 1.4;
        margin: 0 0 rem(38);
        border-bottom: 2px solid $hero;
        clear: both;
        color: #414243;
        @include mobile {
            align-self: center;
        }
    }
}
h2 {
    font: 700 rem(40) / 1.4 $fh;
    margin: rem(34) 0 rem(28);
    border-bottom: 1px solid $hero;
    color: #414243;
    text-transform: capitalize;
    &:first-child {
        margin-top: 0;
    }
    @include mobile {
        align-self: center;
        text-align: center;
    }
}
h3 {
    font: 700 rem(28) / 1.5 $fh;
    clear: both;
    margin: rem(28) 0 rem(18);
    color: $hero;
    @include mobile {
        align-self: center;
        text-align: center;
    }
}
.inline-social {
    align-self: center;
    margin: 0 0 rem(60);
}
.quote-section {
    padding: rem(80) 0 0;
    text-align: center;
    blockquote {
        color: #0e0d0d;
        &:after, &:before {
            color: #aead93;
        }
    }
    @include rtl {
        direction: rtl;
    }
    p {
        padding: rem(24) 0;
        font-size: rem(16);
        font-weight: 400;
        color: #aead93;
        align-self: center;
    }
}
blockquote {
    @include desktop {
        width: 80%;
    }
    margin: auto;
    font: rem(38) / 1.7 $fh;
    color: #48526c;
    @include mobile {
        font-size: 26px;
    }
    &:before {
        content: open-quote;
    }
    &:after {
        content: close-quote;
    }
    &:before, &:after {
        color: $hero;
        font-size: rem(80);
        line-height: 0;
        position: relative;
        top: rem(26);
        @include mobile {
            font-size: 48px;
            top: 10px;
        }
    }
}
section {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    main > &:first-child {
        padding: var(--header-height) 0 0;
    }
}
.image-card-wrapper {
    float: left;
    clear: both;
    width: 100%;
    @include desktop {
        padding: 0 rem(40);
    }
    @include mobile {
        width: calc(100% + 40px);
        margin-left: -20px !important;
    }
    ul {
        float: left;
        clear: both;
        width: 100%;
        position: relative;
        li {
            float: left;
            width: 60%;
            margin: 8% 0;
            position: relative;
            z-index: 20;
            @include rtl {
                float: right;
            }
            @include mobile {
                width: 94%;
                &:first-child {
                    margin: -20% 0 41%;
                }
            }
            &:last-child {
                position: absolute;
                z-index: 10;
                top: 0;
                right: 0;
                height: 100%;
                margin: 0;
                box-shadow: rem(18) rem(28) rem(57) rgba(0, 0, 0, 0.15);
                @include rtl {
                    right: auto;
                    left: 0;
                }
            }
        }
    }
    figure {
        display: block;
        width: 100%;
        height: 100%;
        background: white no-repeat 50% 50% / cover;
    }
    .image-card-text {
        background: white;
        text-align: center;
        padding: 12% 15%;
        box-shadow: rem(18) rem(28) rem(57) rgba(0, 0, 0, 0.04);
        @include mobile {
            padding: 9% 20px;
        }
        p {
            padding: rem(18) 0 0;
            font-size: rem(16);
            line-height: 1;
            letter-spacing: rem(0.5);
            @include mobile {
                font-size: 10px;
            }
        }
        h6 {
            text-align: left;
            font: 700 rem(38) / 1.4 $fh;
            color: $hero;
            @include rtl {
                text-align: right;
            }
            @include mobile {
                font-size: 20px;
                text-align: center;
                @include rtl {
                    text-align: center;
                }
            }
            span {
                color: #414243;
            }
        }
    }
    blockquote {
        width: 100%;
        font-size: rem(20);
        line-height: rem(32);
        &:before, &:after {
            font-size: rem(38);
            margin: 0 rem(4);
            top: rem(9);
        }
    }
    &:first-child {
        margin: rem(80) 0 0;
        @include mobile {
            margin: 30px 0 0;
        }
    }
    .static-city & {
        ul {
            li {
                margin: 4% 0;
                float: right;
                @include mobile {
                    margin: 36% 0 -5%;
                    &:first-child {
                        width: 60%;
                    }
                }
                @include rtl {
                    float: left;
                }
                &:last-child {
                    margin: 0;
                    left: 0;
                    right: auto;
                    @include rtl {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
}
span.fu {
    display: inline-block;
    vertical-align: baseline;
    &::first-letter {
        text-transform: uppercase;
    }
}
.on-hover-affect {
    transition: 700ms opacity, 700ms transform, 700ms filter;
    transform: scale(1);
    filter: saturate(1);
    z-index: 10;
    &:hover, &:active {
        transform: scale(1.04);
        filter: saturate(1.55);
    }
}
.lazy {
    opacity: 0;
    animation: 400ms fadeIn forwards;
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
}
.tmp-items-line-holder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: rem(15);
    justify-content: center;
    margin: rem(20) 0 0;
    > * {
        border-radius: rem(100) !important;
    }
    &.gap-2 {
        gap: rem(30);
        @include mobile {
            img {
                height: rem(100);
            }
        }
    }
}
.inline-yt-player-w {
    width: 100%;
    position: relative;
    padding: 0.5612382234 * 100% 0 0;
    margin: rem(16) 0;
    overflow: hidden;
    @include desktop {
        border-radius: rem(10);
    }
    @include mobile {
        width: calc(100% + 40px);
        margin-left: -20px;
        padding-top: 70%;
    }
    iframe {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.our-partners-title {
    width: 100%;
    display: flex;
    justify-content: center;
}
.circles-holder {
    width: 100%;
    display: grid;
    gap: rem(20);
    grid-template-columns: repeat(3, 1fr);
    margin: 0 0 rem(30);
    @include mobile {
        grid-template-columns: repeat(2, 1fr);
    }
}
.circle-holder {
    width: 100%;
    padding-top: 100%;
    position: relative;
    border-radius: 42%;
    color: #57470b;
    border: rem(1) dashed #6f5b10;
    .circle-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        gap: rem(12);
        width: 100%;
        padding: rem(20);
    }
    .circle-t {
        font: 700 rem(36) / 1 $fh;
        white-space: nowrap;
        @include mobile {
            font-size: rem(30);
        }
    }
    .circle-p {
        font: rem(13) / 1.6 $f;
    }
    &:nth-child(3) ~ & {
        .circle-t {
            font-size: rem(54);
            @include mobile {
                font-size: rem(38);
            }
        }
    }
}
