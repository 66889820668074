@import "../../assets/scss/magic";
.alert-holder {
  --background: white;
  --base-color: #5bad37;
  --base-outline: #{rgba(#5bad37, 0.1)};
  width: 100%;
  height: 100%;
  display: flex;
  padding: rem(30);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  backdrop-filter: blur(rem(4));
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
}
.alert-content {
  background: var(--background);
  margin: auto;
  padding: rem(20) rem(48);
  max-width: rem(700);
  width: 100%;
  position: relative;
  box-shadow: 0 rem(20) rem(40) var(--base-outline);
}
.alert-action-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 rem(24);
  button {
    min-width: rem(140);
  }
}
.success-message {
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: rem(36) 0;
  p, > div {
    margin: rem(20) 0 0;
    font: 300 rem(20) / rem(36) $f;
    &.t-description {
      font: 300 rem(16) / rem(30) $f;
      user-select: text;
    }
    &.t-note {
      opacity: 0.7;
      font: rem(12) / rem(18) $f;
    }
  }
}
.icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  font-size: 16px;
  width: 5em;
  height: 5em;
  margin: auto;
  zoom: normal;
  border: .25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  &.success {
    border-color: var(--base-color);
    [class^=success-circular-line] {
      position: absolute;
      width: 3.75em;
      height: 7.5em;
      transform: rotate(45deg);
      border-radius: 50%;
      background: var(--background);
      &[class$=left] {
        top: -.4375em;
        left: -2.0635em;
        transform: rotate(-45deg);
        transform-origin: 3.75em 3.75em;
        border-radius: 7.5em 0 0 7.5em;
      }
      &[class$=right] {
        top: -.6875em;
        left: 1.875em;
        transform: rotate(-45deg);
        transform-origin: 0 3.75em;
        border-radius: 0 7.5em 7.5em 0;
      }
    }
    .success-ring {
      position: absolute;
      z-index: 2;
      top: -.25em;
      left: -.25em;
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      border: 0.25em solid var(--base-outline);
      border-radius: 50%;
    }
    .success-fix {
      position: absolute;
      z-index: 1;
      top: .5em;
      left: 1.625em;
      width: .4375em;
      height: 5.625em;
      transform: rotate(-45deg);
      background: var(--background);
    }
    [class^=success-line] {
      display: block;
      position: absolute;
      z-index: 2;
      height: .3125em;
      border-radius: .125em;
      background-color: var(--base-color);
      &[class$=tip] {
        top: 2.875em;
        left: .875em;
        width: 1.5625em;
        transform: rotate(45deg);
      }
      &[class$=long] {
        top: 2.375em;
        right: .5em;
        width: 2.9375em;
        transform: rotate(-45deg);
      }
    }
  }
}
.animate-success-icon {
  .success-line-tip {
    animation: animate-success-line-tip 0.75s;
    @keyframes animate-success-line-tip {
      0% {
        top: 1.1875em;
        left: .0625em;
        width: 0;
      }
      54% {
        top: 1.0625em;
        left: .125em;
        width: 0;
      }
      70% {
        top: 2.1875em;
        left: -.375em;
        width: 3.125em;
      }
      84% {
        top: 3em;
        left: 1.3125em;
        width: 1.0625em;
      }
      100% {
        top: 2.8125em;
        left: .875em;
        width: 1.5625em;
      }
    }
  }
  .success-line-long {
    animation: animate-success-line-long 0.75s;
    @keyframes animate-success-line-long {
      0% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
      }
      65% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
      }
      84% {
        top: 2.1875em;
        right: 0;
        width: 3.4375em;
      }
      100% {
        top: 2.375em;
        right: .5em;
        width: 2.9375em;
      }
    }
  }
  .success-circular-line-right {
    animation: rotate-success-circular-line 4.25s ease-in;
    @keyframes rotate-success-circular-line {
      0% {
        transform: rotate(-45deg);
      }
      5% {
        transform: rotate(-45deg);
      }
      12% {
        transform: rotate(-405deg);
      }
      100% {
        transform: rotate(-405deg);
      }
    }
  }
}
