@import "../../assets/scss/magic";
.contact-us-form {
  border-top: var(--header-height) solid transparent;
  max-width: rem(660);
  padding: rem(40) 0 rem(60);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  > p {
    margin: 0 0 rem(24);
    a {
      color: $hero;
      border-bottom: 1px dashed $hero;
      &:hover {
        color: #121623;
      }
    }
  }
  form {
    display: flex;
    width: calc(100% + #{rem(20)});
    align-items: flex-end;
    flex-direction: column;
    margin: rem(24) -#{rem(10)} 0;
    padding: rem(10);
    position: relative;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 100%;
        display: flex;
        @include desktop {
          &:not(:only-child) {
            width: 50%;
            &:first-child {
              padding: 0 rem(12) 0 0;
              @include rtl {
                padding: 0 0 0 rem(12);
              }
            }
            &:last-child {
              padding: 0 0 0 rem(12);
              @include rtl {
                padding: 0 rem(12) 0 0;
              }
            }
          }
        }
      }
    }
  }
  label {
    margin: 0 0 rem(20);
    display: flex;
    width: 100%;
    flex-direction: column;
    p {
      margin: 0 0 rem(12);
      font: rem(15) / 1 $f;
      text-transform: uppercase;
      color: black;
      &.req:after {
        content: " *";
        color: darken($hero, 4);
      }
    }
    input, textarea, select {
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      color: #121623;
      height: rem(50);
      border: 1px dashed $hero;
      border-radius: rem(5);
      resize: vertical;
      padding: 0 rem(24);
      font: 300 rem(16) $f;
      caret-color: darken($hero, 4);
      -webkit-text-fill-color: #121623;
      &:-webkit-autofill {
        -webkit-text-fill-color: #121623;
        box-shadow: inset 0 0 0 rem(100) white;
        background-clip: content-box;
      }
      &:focus {
        border-style: solid;
      }
      @include mobile {
        height: 46px;
      }
    }
    .select-wrapper {
      position: relative;
      .icon-down {
        font-size: rem(20);
        position: absolute;
        top: 50%;
        right: rem(20);
        transform: translateY(-50%);
        color: $hero;
        @include rtl {
          right: auto;
          left: rem(20);
        }
      }
      select {
        padding-right: rem(28);
        @include rtl {
          padding-right: rem(24);
          padding-left: rem(28);
        }
      }
    }
    textarea {
      min-height: rem(180);
      padding: rem(24);
    }
  }
}
.errors-holder {
  width: 100%;
  font: 600 rem(16) / rem(24) $f;
  color: #e22317;
  margin: 0 0 rem(28);
  p + p {
    padding: rem(12) 0 0;
  }
}
