@import '../../assets/scss/magic';
.choose-destination-holder {
    --gap: #{rem(100)};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: white;
    color: #414243;
    z-index: 1000000;
    display: flex;
    @include mobile {
        --gap: #{rem(80)};
    }
    svg {
        display: block;
    }
    path {
        fill: #414243;
        transition: 400ms fill;
    }
    a, .hover-parent {
        cursor: pointer;
        @include hover {
            path {
                fill: black;
            }
            .icon-drop {
                color: black;
            }
        }
    }
}
.cd-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    padding: rem(100) 0;
    @include mobile {
        padding: rem(50) 0;
    }
    > svg {
        @include mobile {
            height: rem(36);
        }
        path {
            fill: black;
        }
    }
}
.cs-content-list {
    margin: rem(120) 0 rem(50);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--gap);
    > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > a {
            display: block;
        }
    }
    .hover-parent {
        display: flex;
        align-items: center;
        .icon-drop {
            font-size: rem(10);
            margin: 0 0 0 rem(6);
            transition: 400ms color, 400ms transform;
            @include mobile {
                font-size: rem(8);
                margin: 0 0 0 rem(10);
            }
            &.active {
                transform: rotate(-180deg);
            }
        }
    }
    svg {
        height: rem(18);
        @include mobile {
            width: auto;
            height: rem(14);
        }
    }
}
.ventures-holder {
    position: relative;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: 500ms margin, 500ms opacity, 500ms max-height;
    &.active {
        margin: var(--gap) 0 0;
        max-height: rem(40);
        opacity: 1;
    }
    svg {
        max-height: rem(14);
        @include mobile {
            height: rem(12);
        }
    }
}
