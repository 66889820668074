@import "../../assets/scss/magic";
.social-items {
  display: flex;
  text-align: center;
  justify-content: center;
  header & {
    @include mobile {
      display: none;
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(48);
    height: rem(48);
    font: rem(16) / rem(48) icomoon;
    transition: 300ms color, 300ms border;
    color: #121623;
    @include mobile {
      color: #403f63;
    }
    header & {
      border-bottom: 1px solid var(--header-text-color);
      color: var(--header-text-color);
    }
    .sticky:not(.show-menu) header & {
      border-bottom-color: transparent;
    }
    @media (hover: hover) {
      &.type-tter:hover {
        color: #00aced;
        border-color: #00aced;
      }
      &.type-iram:hover {
        color: #bc2a8d;
        border-color: #bc2a8d;
      }
      &.type-fook:hover {
        color: #155ef7;
        border-color: #155ef7;
      }
      &.type-apple-music:hover {
        color: #ff2f56;
        border-color: #ff2f56;
      }
      &.type-spotify:hover {
        color: #1ed660;
        border-color: #1ed660;
      }
      &.type-yube:hover {
        color: #cb0000;
        border-color: #cb0000;
      }
    }
  }
}
