@import "../../assets/scss/magic";
.min-gallery-sec {
  display: flex;
  align-items: flex-end;
  @include mobile {
    flex-wrap: wrap;
  }
  > li {
    position: relative;
    width: 100%;
    img {
      display: block;
      width: 100%;
      transform: translate3d(0,0,0);
      filter: saturate(1);
      transition: 500ms filter;
      height: rem(500);
      object-fit: cover;
      object-position: center bottom;
      @include mobile {
        height: 50vh;
      }
    }
    a {
      transform: translate3d(-50%, 0, 0);
      position: absolute;
      bottom: rem(50);
      left: 50%;
      z-index: 20;
      @include mobile {
        white-space: nowrap;
      }
      &:hover {
        transform: translate3d(-50%, -#{rem(5)}, 0);
        + picture img {
          filter: saturate(2);
        }
      }
    }
  }
}
