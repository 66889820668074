@import "../../assets/scss/magic";
.media-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background: white;
  min-height: 100vh;
  @include mobile {
    grid-template-columns: repeat(2, 1fr);
  }
  li {
    display: block;
    width: 100%;
    padding: 100% 0 0;
    overflow: hidden;
    position: relative;
    background: #f5f3f4;
    &:nth-child(11n) {
      grid-column: span 2;
      grid-row: span 2;
    }
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 20%;
      cursor: pointer;
    }
  }
}
