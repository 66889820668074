@import "../../assets/scss/magic";
@import url('https://fonts.googleapis.com/css?family=Allura&display=swap');
.latest-release-section {
    width: 100%;
    padding: rem(80) 0;
    background: $hero;
    color: white;
    overflow: hidden;
    max-width: 100vw;
    @include mobile {
        padding: rem(40) 0 rem(20);
    }
}
.latest-release-holder {
    width: 100%;
    @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 rem(40);
        grid-column-gap: rem(60);
    }
    @include mobile {
        text-align: center;
        padding: 0 rem(20);
    }
    > li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:first-child {
            @include mobile {
                padding: 0 rem(40);
            }
        }
        &:nth-child(2) {
            padding: 0 0 rem(30);
            @include desktop {
                align-items: flex-start;
            }
        }
    }
}
.lr-image-holder {
    padding: 100% 0 0;
    position: relative;
    > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 100%;
        max-width: 100%;
        height: auto;
        box-shadow: 0 0 0 rem(1) rgba(black, 0.1);
    }
}
.lr-artist {
    font: rem(50) / 1 'Allura', serif;
    color: #3e1712;
    @include desktop {
        text-indent: rem(80);
    }
    @include mobile {
        margin: rem(30) 0 0;
    }
}
.lr-s-title {
    font: 700 rem(44) / 1.2 $fh;
    margin: 0 0 rem(30);
    @include mobile {
        font: 700 rem(36) / 1.2 $fh !important;
    }
}
.lr-title {
    font: 700 rem(14) / 1 $f;
    text-transform: uppercase;
    letter-spacing: rem(10);
    color: #7b1209;
    margin: 0 0 rem(40);
}
