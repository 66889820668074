$fontDisplay: swap;
@font-face {
    font-family: 'Roboto';
    font-display: $fontDisplay;
    src: url('../fonts/mardoto/subset-Mardoto-Light.eot');
    src: url('../fonts/mardoto/subset-Mardoto-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/mardoto/subset-Mardoto-Light.woff2') format('woff2'),
    url('../fonts/mardoto/subset-Mardoto-Light.woff') format('woff'),
    url('../fonts/mardoto/subset-Mardoto-Light.ttf') format('truetype'),
    url('../fonts/mardoto/subset-Mardoto-Light.svg#Mardoto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    unicode-range: U+0530-058F;
}
@font-face {
    font-family: 'Roboto';
    font-display: $fontDisplay;
    src: url('../fonts/mardoto/subset-Mardoto-Bold.eot');
    src: url('../fonts/mardoto/subset-Mardoto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/mardoto/subset-Mardoto-Bold.woff2') format('woff2'),
    url('../fonts/mardoto/subset-Mardoto-Bold.woff') format('woff'),
    url('../fonts/mardoto/subset-Mardoto-Bold.ttf') format('truetype'),
    url('../fonts/mardoto/subset-Mardoto-Bold.svg#Mardoto-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    unicode-range: U+0530-058F;
}
@font-face {
    font-family: 'Roboto';
    font-display: $fontDisplay;
    src: url('../fonts/mardoto/subset-Mardoto-Regular.eot');
    src: url('../fonts/mardoto/subset-Mardoto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/mardoto/subset-Mardoto-Regular.woff2') format('woff2'),
    url('../fonts/mardoto/subset-Mardoto-Regular.woff') format('woff'),
    url('../fonts/mardoto/subset-Mardoto-Regular.ttf') format('truetype'),
    url('../fonts/mardoto/subset-Mardoto-Regular.svg#Mardoto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0530-058F;
}
@font-face {
    font-family: 'Playfair Display';
    font-display: $fontDisplay;
    src: url('../fonts/bodoni/bodoni.woff2') format('woff2'),
    url('../fonts/bodoni/bodoni.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    unicode-range: U+0530-058F;
}
@font-face {
    font-family: 'Playfair Display';
    font-display: $fontDisplay;
    src: url('../fonts/bodoni/bodoni.woff2') format('woff2'),
    url('../fonts/bodoni/bodoni.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    unicode-range: U+0530-058F;
}
